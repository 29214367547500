import { useRole } from '@urzhi/www/src/models/useRole';
import { Avatar, AvatarProps, Form } from 'antd';

import interviewerAvatarUrl from '@urzhi/www/src/assets/avatar-interviewer_256.png';
import seekerAvatarUrl from '@urzhi/www/src/assets/avatar-seeker_256.png';
import { ROLE } from '@urzhi/www/src/constants/enum';

export const RoleAvatar: React.FC<
  {
    presetRole?: ROLE;
  } & Omit<AvatarProps, 'src'>
> = ({ presetRole, ...props }) => {
  const userRole = useRole();
  const role = presetRole || userRole;
  return (
    <Avatar
      src={role === ROLE.INTERVIEWER ? interviewerAvatarUrl : seekerAvatarUrl}
      {...props}
    />
  );
};

export const ReadonlyFormRoleAvatarItem: React.FC<{
  label?: string;
  shape?: AvatarProps['shape'];
}> = ({ label, shape }) => (
  /**
   * 样式调整, form label 底边对齐 - 提示暂不支持自定义
   */
  <Form.Item
    className="[&_.ant-form-item-label]:grid [&_.ant-form-item-label]:justify-end [&_.ant-form-item-label]:items-end"
    label={label}
  >
    <div className="flex items-end gap-2">
      <RoleAvatar
        size={100}
        shape={shape || 'square'}
        style={{ cursor: 'not-allowed' }}
      />
      <div className="text-xs text-gray-400 mb-1">{'暂不支持自定义'}</div>
    </div>
  </Form.Item>
);
