import botAvatar from '@/assets/bot-avatar.png';
import { useEffect, useRef } from 'react';
const COZE_JS = `https://lf-cdn.coze.cn/obj/unpkg/flow-platform/chat-app-sdk/1.0.0-beta.4/libs/cn/index.js`;

function loadJs(src: string) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = function () {
      resolve(true);
    };
    script.onerror = function (e: any) {
      reject(e);
    };
    document.head.appendChild(script);
  });
}

let result: Promise<unknown>;

function loadCoze() {
  if (!result) {
    result = loadJs(COZE_JS);
  }
  return result;
}

async function mountCozeBot(el?: HTMLElement | null) {
  await loadCoze();
  const CozeWebSDK = (window as any).CozeWebSDK;
  const icon = `${window.location.origin}${botAvatar}`;
  console.warn('CozeWebSDK', CozeWebSDK, botAvatar);
  //   "https://lf-bot-studio-plugin-resource.coze.cn/obj/bot-studio-platform-plugin-tos/artist/image/7e813aa2c7e14ebb9e2d1a989acfb128.png";
  return new CozeWebSDK.WebChatClient({
    config: {
      // 扣子普通版
      // bot_id: '7401343229893345331',
      // 扣子企业版
      bot_id: '7440799521032486927',
    },
    componentProps: {
      icon,
      title: '面试助手',
    },
    // TODO 接入 auth
    ui: {
      footer: {
        isShow: false,
        expressionText: 'Powered by urzhi.com',
      },
    },
    el,
  });
}

import { createPortal } from 'react-dom';

export function useCozeBot() {
  // const { initialState } = useModel('@@initialState');
  // const isOpen = Boolean(initialState?.uid);
  const inst = useRef<any>();
  const portalRef = useRef<HTMLDivElement>(null);
  // TODO 检测元素 size - 动态覆盖 coze 标记
  const portalNode = createPortal(
    <div
      className="_urzhi_coze_container_ fixed right-6 bottom-8 z-[1000]"
      ref={portalRef}
    ></div>,
    document.body,
  );

  const setIsOpen = (_bool: boolean) => {
    console.info('setIsOpen @depreacted', _bool);
  };

  async function show() {
    if (!inst.current) {
      setIsOpen(true);
      console.info('portalRef.current', portalRef.current);
      inst.current = mountCozeBot(portalRef.current);
    }
  }
  async function hide() {
    if (inst.current) {
      setIsOpen(false);
      (await inst.current).destroy?.();
      inst.current = undefined;
    }
  }

  function toggle() {
    if (!inst.current) {
      show();
    } else {
      hide();
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      loadCoze().then(() => {
        show();
      });
    });
    return () => {
      clearTimeout(timer);
      hide();
    };
  }, []);

  return { portalNode, show, hide, toggle };
}
