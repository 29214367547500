import { track } from '@/tracker';
// 运行时配置
import { RequestConfig } from '@umijs/max';
import { App, ConfigProvider, message } from 'antd';
import zhCN from 'antd/locale/zh_CN';

import { DefaultFooter } from '@/components/Footer/DefaultFooter';
import Header from '@/components/Header';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import { ENABLE_AUTO_REDIRECT } from './constants';
import { ROLE } from './constants/enum';
import { useGlobalStore } from './models/store';
import { judgePath } from './models/usePathState';
import { getSessionCached } from './services/common';
import { RELOGIN_STATE, reLogin, toastReLogin } from './utils/init';

dayjs.locale('zh-cn');
// 全局初始化数据配置，用于 Layout 用户信息和权限初始化
// 更多信息见文档：https://umijs.org/docs/api/runtime-config#getinitialstate
export async function getInitialState(): Promise<API.Session> {
  const { noAuth, shouldLogin } = judgePath();

  if (noAuth) {
    return {};
  }

  try {
    const remoteUserInfo = await getSessionCached({
      DISABLE_TIP: ENABLE_AUTO_REDIRECT,
      IS_SESSION: true,
    });
    // TODO 单次埋点
    useGlobalStore.setState((prev) => ({
      ...prev,
      snap: {
        uid: remoteUserInfo.uid,
        telephone: remoteUserInfo.telephone,
        role: remoteUserInfo.role as ROLE,
        stamp: Date.now(),
      },
    }));
    RELOGIN_STATE.hasLogined = Date.now();
    return remoteUserInfo;
  } catch (error) {
    if (shouldLogin && ENABLE_AUTO_REDIRECT) {
      // 跳转登录页面
      reLogin(true);
    }

    console.error('无登录身份信息', error);
  }

  throw new Error('no login, NEED user info API');
}

export const layout = () => {
  return {
    logo: 'https://img.alicdn.com/tfs/TB1YHEpwUT1gK0jSZFhXXaAtVXa-28-27.svg',
    menu: {
      locale: false,
    },
    layout: 'top',
    menuRender: false,
    headerRender: Header,
    footerRender: DefaultFooter,
    // menuHeaderRender: false,
  };
};

// 请求的配置

export const request: RequestConfig = {
  timeout: 100000,
  errorConfig: {
    errorThrower(res) {
      if (!res?.success) {
        throw new Error(res?.errorMessage || res?.message || 'Request Error');
      }
    },
    errorHandler: (error: any, opts) => {
      if (opts.skipErrorHandler) throw error;
      if (RELOGIN_STATE.hasLogined && opts.IS_SESSION) {
        toastReLogin(error.message);
        return;
      }

      if (!opts.DISABLE_TIP && error.message && !RELOGIN_STATE.opened) {
        message.error(error.message);
      }
    },
  },
};

// Create a client

message.config({
  top: 32,
});

export const rootContainer = function (container: React.ReactNode) {
  return (
    <ConfigProvider
      locale={zhCN}
      theme={{
        cssVar: true,
        token: {
          colorPrimary: '#cf6044',
          colorInfo: '#cf6044',
        },
        components: {
          // btn[type=text] 改为使用 type=link
          Button: {
            colorText: '#cf6044',
            textHoverBg: 'transparent',
            colorBgTextActive: 'transparent',
          },
          Form: {
            labelFontSize: 12,
            labelColor: 'rgba(115, 115, 115, var(--un-text-opacity, 1))',
            labelHeight: 14,
            verticalLabelPadding: '0 0 0px',
          },
        },
      }}
    >
      <App>{container}</App>
    </ConfigProvider>
  );
  // return <ChakraBaseProvider>{container}</ChakraBaseProvider>;
};

export function onRouteChange(payload: any) {
  track?.('pageview', {
    url: payload.location.pathname,
    meta: { isFirst: payload.isFirst },
  });
  // console.debug('onRouteChange', payload);
}
