export enum ROLE {
  SEEKER = 'SEEKER',
  INTERVIEWER = 'INTERVIEWER',
}

export enum PositionType {
  实习 = 'INTERNSHIP', // 颜色: #FFA500
  校招 = 'CAMPUS', //  颜色: #00CED1
  社招 = 'EXPERIENCED', // 颜色: #2F4F4F
}

export const PositionTypeColor = {
  [PositionType.实习]: '#FFA500',
  [PositionType.校招]: '#00CED1',
  [PositionType.社招]: '#2F4F4F',
};

/** 支付状态 */
export enum PayStatus {
  未支付 = 'NOT_PAY',
  支付中 = 'PAYING',
  已支付 = 'PAYED',
  失败 = 'FAILED',
}

export const PayStatusColor = {
  [PayStatus.未支付]: '#E53E3E', // 调整后的红色
  [PayStatus.支付中]: '#ECC94B', // 调整后的柔和黄色
  [PayStatus.已支付]: '#38A169', // 调整后的深绿色
  [PayStatus.失败]: '#C53030', // 调整后的深红色
};

/** 订单状态 */
export enum BookingStatus {
  预约中 = 'BOOKING',
  已确认 = 'ACCEPTED',
  已拒绝 = 'REJECTED',
  已完成 = 'FINISH',
  已取消 = 'CANCELED',
  已作废 = 'INVALID',
}

/** 订单状态 */
export enum OrderStatus {
  预约中 = 'BOOKING',
  已确认 = 'ACCEPTED',
  已取消 = 'CANCELED',
  已拒绝 = 'REJECTED',
  支付中 = 'PAYING',
  已支付 = 'PAYED',
  待评价 = 'WAITING_EVALUATION',
  已完成 = 'DONE',
  退款中 = 'REFUNDING',
  已退款 = 'REFUNDED',
}

export const OrderStatusColor = {
  [OrderStatus.预约中]: '#FF99AC', // 调整后的鲜艳粉色
  [OrderStatus.已确认]: '#63B3ED', // 调整为较为饱和的淡蓝色
  [OrderStatus.已取消]: '#A0AEC0', // 调整为更深的灰色，以增强对比
  [OrderStatus.已拒绝]: '#DD6B20', // 调整为较深的橙色，以降低亮度提高可读性
  [OrderStatus.支付中]: '#ECC94B', // 调整后的暖黄色
  [OrderStatus.已支付]: '#38A169', // 调整后的深绿色
  [OrderStatus.待评价]: '#D69E2E', // 调整为较深的金色，降低亮度
  [OrderStatus.已完成]: '#4FD1C5', // 调整为青绿色，以区分于其他绿色状态并降低亮度
  [OrderStatus.退款中]: '#F8C0A5', // 接近橙色
  [OrderStatus.已退款]: '#A3E6A3', // 接近绿色
};

export enum WalletFlowStatus {
  在途 = 'IN_TRANSIT',
  已入账 = 'POSTING',
  已拒绝 = 'REJECT',
  退款 = 'REFUND',
}

export enum PayWay {
  微信支付 = 'WECHAT',
  支付宝 = 'ALI_PAY',
}

export enum TransferStatus {
  发起 = 'SUBMIT',
  转账中 = 'PENDING',
  成功 = 'SUCCESS',
  拒绝 = 'REJECT',
  失败 = 'FAILED',
}

export const TransferStatusColor = {
  [TransferStatus.发起]: '#FF99AC', // 调整后的鲜艳粉色
  [TransferStatus.失败]: '#A0AEC0', // 调整为更深的灰色，以增强对比
  [TransferStatus.拒绝]: '#DD6B20', // 调整为较深的橙色，以降低亮度提高可读性
  [TransferStatus.转账中]: '#ECC94B', // 调整后的暖黄色
  [TransferStatus.成功]: '#38A169', // 调整后的深绿色
};

export const WalletFlowStatusColor = {
  [WalletFlowStatus.在途]: '#ECC94B',
  [WalletFlowStatus.已入账]: '#38A169',
  [WalletFlowStatus.已拒绝]: '#DD6B20',
  [WalletFlowStatus.退款]: '#A0AEC0',
};

export enum WalletBizType {
  面试取消补偿 = 'REFUND_FLOW',
  面试收入 = 'INTERVIEW_FLOW',
  推广奖励收入 = 'REWARD_FLOW',
}

export enum WalletBizInOutType {
  入帐 = 1,
  出账 = 0,
}

export enum CertificationStatus {
  草稿 = 'SAVED',
  审核中 = 'AUDITING',
  审核通过 = 'APPROVED',
  已拒绝 = 'REJECTED',
  已取消 = 'CANCELED',
}

export const CertificationStatusColor = {
  [CertificationStatus.草稿]: '#A0AEC0',
  [CertificationStatus.审核中]: '#ECC94B',
  [CertificationStatus.审核通过]: '#38A169',
  [CertificationStatus.已拒绝]: '#DD6B20',
  [CertificationStatus.已取消]: '#A0AEC0',
};

export enum AuditStatus {
  审批中 = 'APPROVING',
  已拒绝 = 'REJECTED',
  已完成 = 'FINISH',
}
export const AuditStatusColor = {
  [AuditStatus.审批中]: '#ECC94B',
  [AuditStatus.已完成]: '#38A169',
  [AuditStatus.已拒绝]: '#DD6B20',
};

export enum MaterialsType {
  工卡 = 'WORK_CARD',
  身份证正面 = 'ID_FRONT',
  身份证背面 = 'ID_BACK',
  其他 = 'OTHERS',
}

export enum QingqueInterviewStaus {
  未开始 = 'NOT_STARTED',
  结束 = 'CLOSED',
  已开始 = 'RUNNING',
  已取消 = 'CANCELED',
}

export const QingqueInterviewStausColor = {
  [QingqueInterviewStaus.未开始]: '#A0AEC0',
  [QingqueInterviewStaus.结束]: '#4FD1C5',
  [QingqueInterviewStaus.已开始]: '#38A169',
  [QingqueInterviewStaus.已取消]: '#A0AEC0',
};

export function getEnumLabel<T>(enumEntries: [string, T][], value?: T) {
  return enumEntries.find((item) => item[1] === value)?.[0] || value;
}
